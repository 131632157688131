export default {
  methods: {
    handleException(e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        this.$router.push({ name: 404 });
      }
    },
  },
};
