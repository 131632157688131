export default {
  methods: {
    getClassBadgeLength(count) {
      let className = '';

      switch (String(count).length) {
        case 3:
          className = 'three';
          break;
        case 2:
          className = 'two';
          break;
        case 1:
          className = '';
          break;
        default:
          className = 'three';
          break;
      }

      return className;
    },
  },
};
