import multiguard from 'vue-router-multiguard';
import UserGuard from '../guards/User';

export default [
  {
    path: '/groups/:id',
    name: 'profileGroupsView',
    component: () => import(/* webpackChunkName: "profileGroupsView" */ '@/views/profile/MyGroups/Group/Feed.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'group-layout',
      path: '/groups',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/groups/:id/projects',
    name: 'profileGroupStrategyProjects',
    component: () => import(/* webpackChunkName: "profileGroupStrategyProjects" */ '@/views/profile/MyGroups/Group/StrategyProjects.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'group-layout',
      path: '/groups/projects',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/groups/:id/projects/create',
    name: 'groupStrategyProjectCreate',
    component: () => import(/* webpackChunkName: "strategyProjectPageView" */ '@/views/pages/StrategyProjectCreate/StrategyProjectCreate.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      path: '/groups/projects/create',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/groups/:id/members',
    name: 'profileGroupMembers',
    component: () => import(/* webpackChunkName: "profileGroupMembers" */ '@/views/profile/MyGroups/Group/Members.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'group-layout',
      path: '/groups/members',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      scrollToTop: false,
    },
  },
];
