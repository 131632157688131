export default {
  methods: {
    hideModal(id) {
      // document.body.style.overflow = 'auto';
      document.querySelector(`#${id}`).style.display = 'none';
    },

    showModal(id) {
      // document.body.style.overflow = 'hidden';
      document.querySelector(`#${id}`).style.display = 'block';
    },
    runModalActionMethod(method) {
      console.log(method);
      this[method]();
    },
  },
};
