<template>
  <div>
    <div class="project-header__wrapper">
      <div class="container">
        <div v-if="text" class="container_alert">
          <Alert
            :variant="variant || alertText" v-if="text || alertText"
          >{{ text || alertText }}</Alert>
        </div>
        <div class="project-header">
          <div v-if="showAvatars" class="project-header__users sproject-members__box">
            <FiveAvatars
              :users="users"
              :countUsers="membersLength"
              :role="role"
              :isGroup="true"
            />
          </div>
          <nav
            v-if="role !== 'guest' && role !== 'request' && role !== 'hidden'"
            class="project-header__actions"
          >
            <ul class="project-header__menu">
              <li class="project-header__menu-item">
                <InviteModal
                  :groupID="groupID"
                  v-on:invite="onInvite"
                />
              </li>
              <li
                v-if="routeName === 'profileGroupMembers'
                  || routeName === 'profileFriends'"
                class="project-header__menu-item"
              >
                <SearchMenu @onsearch="onSearch"/>
              </li>
              <li v-if="showActivityGroupMenu" class="project-header__menu-item">
                <ActivityGroupMenu :isActivity="isActivity"/>
              </li>
            </ul>
          </nav>
          <div v-if="type === 'Open' && role === 'guest'" class="btns-group--right">
            <a
              class="btn-edit"
              @click.prevent="onRequestMembership"
            >
              Join the group
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <UserCabinetMenu v-if="showMenu" :menu="menu"/>
    </div>
    <Modal
      @close="hideModal(modalSendGroupRequest)"
      :id=modalSendGroupRequest
      title=""
      buttonTextOk="Yes"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalSendGroupRequest)"
      @ok="sendRequestGroup"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import GroupService from '@/services/GroupService';

import InviteModal from '@/views/profile/MyGroups/Group/Components/InviteModal.vue';
import ActivityGroupMenu from '@/views/profile/MyGroups/Group/Components/ActivityGroupMenu.vue';
import SearchMenu from '@/views/profile/MyGroups/Group/Components/SearchMenu.vue';
import UserCabinetMenu from '@/components/profile/UserCabinetMenu.vue';
import FiveAvatars from '@/components/FiveAvatars.vue';
import Alert from '@/components/Alert.vue';
import Modal from '@/components/Modal.vue';

import menu from '@/mixins/menu/group';
import modal from '@/mixins/modal';
import alert from '@/mixins/alert';

export default {
  name: 'ProjectGroupHeader',
  mixins: [menu, modal, alert],
  components: {
    InviteModal,
    ActivityGroupMenu,
    UserCabinetMenu,
    SearchMenu,
    FiveAvatars,
    Alert,
    Modal,
  },
  props: {
    users: {
      type: Array,
    },
    membersLength: {
      type: Number,
      default: 0,
    },
    groupID: {
      type: Number,
      default: 0,
    },
    role: {
      type: String,
      default: 'guest',
    },
    type: {
      type: String,
      default: 'Open',
    },
    isActivity: {
      type: Boolean,
      default: false,
    },
    showAvatars: {
      type: Boolean,
      default: true,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    showActivityGroupMenu: {
      type: Boolean,
      default: true,
    },
    alertText: {
      type: String,
      default: '',
    },
    alertVariant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalSendGroupRequest: 'modalSendGroupRequest',
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    onInvite(data) {
      this.$emit('invite', data);
    },
    onSearch(members, pagination, searchText) {
      this.$emit('search', members, pagination, searchText);
    },
    async onRequestMembership() {
      this.showModal(this.modalSendGroupRequest);
    },
    async sendRequestGroup() {
      try {
        const res = await GroupService.actionUser(this.groupID, { action: 'request' });

        if (res.data.status === 200) {
          // const alertText = {
          //   text: res.data.message,
          //   variant: 'success',
          // };
          this.$eventBus.$emit('updateUserRole', 'member');
          this.hideModal(this.modalSendGroupRequest);
          this.setAlert({ textArg: res.data.message, variantArg: 'success' });
          // this.$router.push({ name: 'strategyProjectsPage', params: { alertText } });
        }
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .invite {
    ::v-deep button {
      padding: 13px 30px;
      border-radius: 10px;
      border: none;
      font-size: 16px;
      line-height: 26px;
      outline: none;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }
  }
</style>
