export default {
  state: {
    auhtUser: Boolean(localStorage.getItem('token') === null ? 0 : 1),
    me: null,
    isBuyer: true,
    loading: false,
  },
  getters: {
    getAuthUser: (state) => state.auhtUser,
    isSetUser: (state) => Boolean(state.me),
    getMe: (state) => state.me,
    isBuyer: (state) => state.isBuyer,
    isLoading: (state) => state.loading,
  },
  mutations: {
    SET_IS_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_AUTH_USER(state, payload) {
      state.auhtUser = payload;
    },
    SET_ME(state, payload) {
      state.me = payload;
    },
    REMOVE_ME(state) {
      state.me = null;
    },
    SET_IS_BUYER(state, payload) {
      state.isBuyer = payload;
    },
  },
};
