<template>
  <div id="app">
    <Header :header="settingLayout.header" />
    <UserBanner v-if="$route.meta.isHeader" />
    <div class="user-cabinet">
      <div class="container">
        <UserCabinetMenu v-if="$route.meta.isMenu" :menu="menu"/>
        <router-view />
      </div>
    </div>
    <Cookie v-if="!isAccepedCookie" @onAcceptCookie="acceptCookie"/>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import Cookie from '@/components/layout/Cookie.vue';
import UserBanner from '@/components/profile/UserBanner.vue';
import UserCabinetMenu from '@/components/profile/UserCabinetMenu.vue';

// import store from '@/store';

import menu from '@/mixins/menu/profile';
import content from '@/mixins/content';
import cookie from '@/mixins/cookie';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

export default {
  name: 'Profile',
  mixins: [menu, content, cookie, websocket, me],
  components: {
    Header,
    Cookie,
    Footer,
    UserBanner,
    UserCabinetMenu,
  },
  props: {
    settingLayout: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {
    this.subscribeToChannel('marketplace', this.setUnreadMarketplaceFromSocket);
    this.subscribeToChannel('profile-feed', this.setUnreadFeedsFromSocket);
    this.subscribeToChannel('profile-projects', this.setUnreadProjectsFromSocket);
    this.subscribeToChannel('profile-groups', this.setUnreadGroupsFromSocket);
    this.subscribeToChannel('profile-friends', this.setUnreadFriendsFromSocket);
  },
  methods: {
    setUnreadMarketplaceFromSocket(event) {
      const data = JSON.parse(event.data);
      // data.buyer.projects = { 97: 5, 95: 1, 93: 2 };
      // data.buyer.offers = { 81: 4, 72: 1 };
      // data.seller.total = 7;
      // data.seller.messages = { 13: 2, 27: 5 };
      // console.log(data, ' data / this.isBuyer', this.isBuyer);
      this.$store.commit('SET_UNREAD_MARKETPLACE_DATA', data);
      if (this.isBuyer) {
        this.$eventBus.$emit('setMatchmaking', data.buyer.total, 'matchmaking');
      } else {
        this.$eventBus.$emit('setMatchmaking', data.seller.total, 'matchmaking');
      }
      // console.log(data, ' DATA', this.isBuyer);
      // this.$eventBus.$emit('setMatchmaking', data, 'marketplace');
    },
    setUnreadFeedsFromSocket(event) {
      const data = JSON.parse(event.data).message;
      const newFeed = JSON.parse(event.data).update;
      this.$store.commit(
        'SET_UNREAD_FEEDS',
        [...this.$store.getters.getUnreadFeeds, ...data.feed],
      );

      if (this.$route.name === 'profileFeed') {
        this.$eventBus.$emit('setFeedsCount', 0, 'feeds');
      }

      if (newFeed) {
        this.$eventBus.$emit('addedProfileFeed', newFeed);
      }
    },
    setUnreadProjectsFromSocket(event) {
      const data = JSON.parse(event.data).message;
      this.$store.commit('SET_UNREAD_PROJECTS', data.projects);

      this.$eventBus.$emit('setGroupsCount', data.groups, 'groups');
      this.$eventBus.$emit('setFeedsCount', data.feed, 'feeds');
      this.$eventBus.$emit('setFriendsCount', data.friends, 'friends');
    },
    setUnreadGroupsFromSocket(event) {
      const data = JSON.parse(event.data).message;
      this.$store.commit('SET_UNREAD_GROUPS', data.groups);

      this.$eventBus.$emit('setProjectsCount', data.projects, 'projects');
    },
    setUnreadFriendsFromSocket(event) {
      const data = JSON.parse(event.data).message;
      const newFriend = JSON.parse(event.data).update;

      this.$store.commit(
        'SET_UNREAD_MEMBERS',
        [...this.$store.getters.getUnreadMembers, ...data.friends],
      );

      if (this.$route.name === 'profileFriends') {
        this.$eventBus.$emit('setFriendsCount', 0, 'friends');
      }

      if (newFriend) {
        this.$eventBus.$emit('addedProfileFriend', newFriend);
      }
    },
  },
  computed: {
    isBuyer() {
      return this.$route.params.type === 'buyer';
    },
  },
  watch: {
    /* eslint-disable */
    '$route.name': function (newVal, oldVal) {
      if (newVal === 'profileMatchmaking') {
        this.$eventBus.$emit('setMatchmaking', this.$store.getters.getUnreadBuyerMarketplace, 'matchmaking');
      }
    },
  },
};
</script>
