export default {
  state: {
    unreadProjects: [],
    unreadGroups: [],
    unreadMembers: [],
    unreadFeeds: [],
    unreadBuyerMarketplace: [],
    unreadSellerMarketplace: [],
    unreadMarketplaceData: {},
  },
  getters: {
    getUnreadProjects: (state) => state.unreadProjects,
    getUnreadGroups: (state) => state.unreadGroups,
    getUnreadMembers: (state) => state.unreadMembers,
    getUnreadFeeds: (state) => state.unreadFeeds,
    getUnreadBuyerMarketplace: (state) => state.unreadBuyerMarketplace,
    getUnreadSellerMarketplace: (state) => state.unreadSellerMarketplace,
    getUnreadMarketplaceData: (state) => state.unreadMarketplaceData,
  },
  mutations: {
    SET_UNREAD_PROJECTS(state, payload) {
      state.unreadProjects = payload;
    },
    SET_UNREAD_GROUPS(state, payload) {
      state.unreadGroups = payload;
    },
    SET_UNREAD_MEMBERS(state, payload) {
      state.unreadMembers = payload;
    },
    SET_UNREAD_FEEDS(state, payload) {
      state.unreadFeeds = payload;
    },
    SET_UNREAD_BUYER_MARKETPLACE(state, payload) {
      state.unreadBuyerMarketplace = payload;
    },
    SET_UNREAD_SELLER_MARKETPLACE(state, payload) {
      state.unreadSellerMarketplace = payload;
    },
    SET_UNREAD_MARKETPLACE_DATA(state, payload) {
      state.unreadMarketplaceData = payload;
      this.commit('SET_UNREAD_BUYER_MARKETPLACE', payload.buyer.total);
      this.commit('SET_UNREAD_SELLER_MARKETPLACE', payload.seller.total);
    },
  },
};
