export default {
  data() {
    return {
      sockets: [],
    };
  },
  methods: {
    subscribeToChannel(channel, getMessages, item_id = undefined) {
      const websocket = new WebSocket(this.$websocketUrl);
      this.sockets.push(websocket);

      websocket.addEventListener('open', () => {
        websocket.send(JSON.stringify({
          channel,
          id: this.me.id,
          item_id,
          command: 'subscribe',
        }));
      });

      websocket.addEventListener('message', getMessages);

      websocket.addEventListener('close', (event) => {
        console.log('Close websocket', event);
      });
    },
    getLength(data) {
      return data.map((obj) => Object.values(obj).reduce((acc, curVal) => {
        if (!Array.isArray(curVal)) return acc;

        return acc + curVal.length;
      }, 0)).reduce((acc, curVal) => acc + curVal, 0);
    },
  },
  watch: {
    'alert.text': function alertText() {
      if (!this.alert.text) {
        return;
      }

      this.showAndHideAlert();
    },
  },
  destroyed() {
    this.sockets.forEach((socket) => { socket.close(); });
  },
};
