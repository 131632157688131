<template>
  <nav>
    <ul class="user-cabinet__menu">
      <li
        class="user-cabinet__item"
        v-for="item in menu"
        :key="item.link + item.title"
        @click="setActive(item.link)"
        @mouseover="setActive(item.link)"
        @mouseleave="setActive(selectedLink)"
      >
        <span
          v-if="(item.slug === 'friends'
              || item.slug === 'projects'
              || item.slug === 'groups'
              || item.slug === 'feeds'
              || item.slug === 'matchmaking'
            )
            && unreadMessagesCount[item.slug]"
          class="message_badge"
          :class="classBadgeLength[item.slug]">{{unreadMessagesCount[item.slug]}}
        </span>
        <router-link
          class="user-cabinet__link"
          :class="{ active: item.active }"
          :to="{ name: item.link }"
        >
          <i :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'UserCabinetMenu',
  mixins: [badge],
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedLink: this.$route.name,
      unreadMessagesCount: {
        matchmaking: 0,
        friends: 0,
        projects: 0,
        groups: 0,
        feeds: 0,
      },
      classBadgeLength: {
        matchmaking: '',
        friends: '',
        projects: '',
        groups: '',
        feeds: '',
      },
    };
  },
  created() {
    this.setActive(this.selectedLink);

    this.$eventBus.$on('setMatchmaking', this.setUnreadMessages);
    this.$eventBus.$on('setFriendsCount', this.setUnreadMessages);
    this.$eventBus.$on('setProjectsCount', this.setUnreadMessages);
    this.$eventBus.$on('setGroupsCount', this.setUnreadMessages);
    this.$eventBus.$on('setFeedsCount', this.setUnreadMessages);
  },
  watch: {
    /* eslint-disable func-names */
    '$route.name': function (name) {
      this.selectedLink = name;
      this.setActive(this.selectedLink);
    },
  },
  /* eslint-disable no-return-assign, no-param-reassign */
  methods: {
    setDefaultMenu() {
      this.menu.forEach((item) => {
        if (item.link === this.selectedLink) return;
        item.active = false;
      });
    },
    setActive(linkName) {
      this.setDefaultMenu();
      const currentMenu = this.menu.find((i) => i.link === linkName);
      if (currentMenu) currentMenu.active = true;
    },
    setUnreadMessages(count, type) {
      this.unreadMessagesCount[type] = +count;
      this.classBadgeLength[type] = this.getClassBadgeLength(count);
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('setMatchmaking', this.setUnreadMessages);
    this.$eventBus.$off('setFriendsCount', this.setUnreadMessages);
    this.$eventBus.$off('setProjectsCount', this.setUnreadMessages);
    this.$eventBus.$off('setGroupsCount', this.setUnreadMessages);
    this.$eventBus.$off('setFeedsCount', this.setUnreadMessages);
  },
};
</script>

<style lang="scss" scoped>
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    right: -20px;
  }
}

.user-cabinet__item {
  position: relative;
}
</style>
