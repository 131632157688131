<template>
  <div id="app">
    <Header :header="settingLayout.header" />
    <router-view />
    <Cookie v-if="!isAccepedCookie" @onAcceptCookie="acceptCookie"/>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import Cookie from '@/components/layout/Cookie.vue';

import cookie from '@/mixins/cookie';

export default {
  props: {
    settingLayout: {
      type: Object,
    },
  },
  components: {
    Header,
    Cookie,
    Footer,
  },
  mixins: [cookie],
  created() {},
};
</script>
