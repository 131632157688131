<template>
  <div class="container">
    <div class="cookies">
      <div class="cookies__wrapper">
        <div class="cookies__img"></div>
        <div class="cookies__content">
          This website uses cookies to ensure you get the best experience on our
          website. More information here: <strong><a href="https://wethink.eu/privacy-terms">https://wethink.eu/privacy-terms</a></strong>
        </div>
        <a class="cookies__btn" href="#" @click.prevent="acceptCookie">Got It</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    acceptCookie() {
      // +1 день от текущей даты
      let date = new Date(Date.now() + (86400e3 * 365));
      date = date.toUTCString();

      document.cookie = `acceptedCookie=1;path=/;expires=${date}`;
      this.$emit('onAcceptCookie');
    },
  },
};
</script>
