import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from './modules/AuthModule';
import ProfileModule from './modules/ProfileModule';
import GroupModule from './modules/GroupModule';
import ProjectModule from './modules/ProjectModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    profile: ProfileModule,
    group: GroupModule,
    project: ProjectModule,
  },
});
