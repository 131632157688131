import multiguard from 'vue-router-multiguard';
import UserGuard from '../guards/User';

export default [
  {
    path: '/projects/:id/edit',
    name: 'strategyProjectsEditor',
    component: () => import(/* webpackChunkName: "strategyProjectsEditor" */ '@/views/pages/StrategyProjectEditor/Index.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'editor-layout',
      path: '/projects/edit',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
];
