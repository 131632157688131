import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { setCurrentUserData } from '@/helpers/currentUserData';
import { initSettingsVeeValidate } from '@/libs/vee-validate';
import VueTextareaAutosize from 'vue-textarea-autosize';
import {
  VBToggle, BFormGroup, BFormInput,
} from 'bootstrap-vue';
import VueMapbox from 'vue-mapbox';
import Mapbox from 'mapbox-gl';
import App from './App.vue';
import router from './router';
import store from './store';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './assets/scss/style.scss';
import './assets/css/style.min.css';
import 'bootstrap';

setCurrentUserData();
initSettingsVeeValidate();

Vue.use(VueMapbox, { mapboxgl: Mapbox });
Vue.use(VueCompositionAPI);
Vue.use(VueTextareaAutosize);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.directive('b-toggle', VBToggle);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-input', BFormInput);

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$etherpadServerUrl = process.env.VUE_APP_ETHERPAD_URL;
Vue.prototype.$stripePublicKey = process.env.VUE_APP_STRIPE_KEY;
Vue.prototype.$websocketUrl = process.env.VUE_APP_WEBSOCKET_URL;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
