import multiguard from 'vue-router-multiguard';
import UserGuard from '../guards/User';
// import GuestGuard from '../guards/Guest';

export default [
  {
    path: '/projects',
    name: 'strategyProjectsPage',
    component: () => import(/* webpackChunkName: "StrategyProjects" */ '@/views/pages/StrategyProjects/StrategyProjects.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/projects/create',
    name: 'strategyProjectCreate',
    component: () => import(/* webpackChunkName: "strategyProjectPageView" */ '@/views/pages/StrategyProjectCreate/StrategyProjectCreate.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/projects/:id',
    name: 'strategyProjectPageView',
    component: () => import(/* webpackChunkName: "strategyProjectPageView" */ '@/views/pages/StrategyProjectView/StrategyProjects.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      path: '/projects',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/messages',
    name: 'MessagesPage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Messages/Messages.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/press',
    name: 'PressPage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Press/Press.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/press/:id',
    name: 'PressPageSingle',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Press/PressSingle.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      path: '/press',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Privacy/Privacy.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/legal-notice',
    name: 'LegalNoticePage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/LegalNotice/LegalNotice.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/About/About.vue'),
    // beforeEnter: multiguard([UserGuard, GuestGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Contact/Contact.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/donation',
    name: 'DonatePage',
    component: () => import(/* webpackChunkName: "MessagesPage" */ '@/views/pages/Donate/Donate.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/groups',
    name: 'PrivateGroupsPage',
    component: () => import(/* webpackChunkName: "PrivateGroupsPage" */ '@/views/pages/PrivateGroups/PrivateGroups.vue'),
    // beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
];
