import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import GroupRoutes from './path/group';
import AuthRoutes from './path/auth';
import ProfileRoutes from './path/profile';
import PageRoutes from './path/page';
import EditorRoutes from './path/editor';

Vue.use(VueRouter);

const routes = [
  ...GroupRoutes,
  ...AuthRoutes,
  ...ProfileRoutes,
  ...PageRoutes,
  ...EditorRoutes,
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      layout: 'page-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    if (
      to.name === 'profileGroupMembers'
      && from.name === 'profileGroupMembers'
      && (to.query.page || from.query.page)
      && (to.query['per-page'] || from.query['per-page'])) {
      return;
    }

    if (
      to.name === 'profileGroupsView'
      && from.name === 'profileGroupsView'
      && (to.query.page || from.query.page)
      && (to.query['per-page'] || from.query['per-page'])) {
      return;
    }
    window.scrollTo(0, 0);
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthUser = store.getters.getAuthUser;

  if (isAuthUser && to.path === '/') {
    return next('/profile/matchmaking/buyer');
  }
  return next();
});

export default router;
