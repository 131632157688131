import multiguard from 'vue-router-multiguard';
import UserGuard from '../guards/User';

export default [
  {
    path: '/profile/settings',
    name: 'profileSetting',
    component: () => import(/* webpackChunkName: "profileSetting" */ '@/views/profile/Settings.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/about',
    name: 'profileAbout',
    component: () => import(/* webpackChunkName: "profileAbout" */ '@/views/profile/About.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/feed',
    name: 'profileFeed',
    component: () => import(/* webpackChunkName: "profileFeed" */ '@/views/profile/Feed/Index.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/friends',
    name: 'profileFriends',
    component: () => import(/* webpackChunkName: "profileFriends" */ '@/views/profile/Friends/Friends.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/groups',
    name: 'profileGroups',
    component: () => import(/* webpackChunkName: "profileGroups" */ '@/views/profile/MyGroups/Index.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/group/request',
    name: 'profileGroupsRequest',
    component: () => import(/* webpackChunkName: "profileGroupsRequest" */ '@/views/profile/MyGroups/Request/Index.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: false,
      isMenu: false,
    },
  },
  {
    path: '/profile/projects',
    name: 'strategyProjects',
    component: () => import(/* webpackChunkName: "MyStrategyProjects" */ '@/views/profile/MyStrategyProjects.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/matchmaking/:type?',
    name: 'profileMatchmaking',
    component: () => import(/* webpackChunkName: "profileMatchmaking" */ '@/views/profile/Matchmaking/Index.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/matchmaking/:type/project/:id',
    name: 'projectDetails',
    component: () => import(/* webpackChunkName: "projectDetails" */ '@/views/profile/Matchmaking/Shared/Project.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
  {
    path: '/profile/matchmaking/seller/offers',
    name: 'profileMatchmakingSellerOffers',
    component: () => import(/* webpackChunkName: "profileMatchmakingSellerOffers" */ '@/views/profile/Matchmaking/Seller/Offers.vue'),
    beforeEnter: multiguard([UserGuard]),
    meta: {
      layout: 'profile-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
      isHeader: true,
      isMenu: true,
    },
  },
];
