import store from '@/store';

export default function (to, from, next) {
  const isAuthUser = store.getters.getAuthUser;
  if (!isAuthUser) {
    next();
  } else {
    next({ name: 'mainAuthorization' });
  }
}
