<template>
  <div :class="`alert alert-${variant}`"><slot></slot></div>
</template>
<script>
export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
</style>
