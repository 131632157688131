export default {
  state: {
    unreadPostsCount: 0,
    unreadMembersCount: 0,
    unreadPosts: [],
  },
  getters: {
    getUnreadPostsCount: (state) => state.unreadPostsCount,
    getUnreadMembersCount: (state) => state.unreadMembersCount,
    getUnreadPosts: (state) => state.unreadPosts,
  },
  mutations: {
    SET_UNREAD_POSTS_COUNT(state, payload) {
      state.unreadPostsCount = payload;
    },
    SET_UNREAD_POSTS(state, payload) {
      state.unreadPosts = payload;
    },
    SET_UNREAD_MEMBERS_COUNT(state, payload) {
      state.unreadMembersCount = payload;
    },
  },
};
