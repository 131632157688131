import router from '@/router';
import store from '@/store';
import UserService from '@/services/UserService';

export const setCurrentUserData = async () => {
  router.beforeEach(async (to, from, next) => {
    const { isSetUser, getAuthUser } = store.getters;

    if (!isSetUser && getAuthUser) {
      try {
        const { data } = await UserService.current();
        store.commit('SET_ME', data);
        next();
      } catch (error) {
        console.log(error);
      }
    } else {
      next();
    }
  });
};
