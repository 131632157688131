export default {
  state: {
    unreadGroupProjects: null,
    unreadGroupFeeds: null,
    unreadGroupMembers: null,
  },
  getters: {
    getUnreadGroupProjects: (state) => state.unreadGroupProjects,
    getUnreadGroupFeeds: (state) => state.unreadGroupFeeds,
    getUnreadGroupMembers: (state) => state.unreadGroupMembers,
  },
  mutations: {
    SET_UNREAD_GROUP_PROJECTS(state, payload) {
      state.unreadGroupProjects = payload;
    },
    SET_UNREAD_GROUP_FEEDS(state, payload) {
      state.unreadGroupFeeds = payload;
    },
    SET_UNREAD_GROUP_MEMBERS(state, payload) {
      state.unreadGroupMembers = payload;
    },
    RESET_UNREAD_GROUP_PROJECTS(state) {
      state.unreadGroupProjects = null;
    },
    RESET_UNREAD_GROUP_FEEDS(state) {
      state.unreadGroupFeeds = null;
    },
    RESET_UNREAD_GROUP_MEMBERS(state) {
      state.unreadGroupMembers = null;
    },
  },
};
