import axios from '@/libs/axios';

export default {
  /**
     * Send request for register user
     * @returns {Object}
     */
  async register(data) {
    const res = await axios.post('users', data);
    return res;
  },
  /**
     * Send request for login user
     * @returns {Object}
     */
  async login(data) {
    const res = await axios.post('users/login', data);
    return res;
  },

  /**
     * Send request for auth user use social media
     * @returns {Object}
     */
  async connect(data) {
    const res = await axios.post('users/connect', data);
    return res;
  },
  /**
     * Send request if user forgot password
     * @returns {Object}
     */
  async forgotPassword(data) {
    const res = await axios.post('users/forgot', data);
    return res;
  },
  /**
     * Send request for comfirm token
     * @returns {Object}
     */
  async confirm(data) {
    const res = await axios.post('users/confirm', data);
    return res;
  },

  /**
     * Send request for logout user
     * @returns {Object}
     */
  async logout() {
    const res = await axios.post('users/logout');
    return res;
  },
};
