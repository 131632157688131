import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import { extend } from 'vee-validate';

export const setMessageRules = () => {
  Object.keys(rules).forEach((rule) => {
    messages.max = 'The {_field_} field may not be longer than {length} characters';

    extend(rule, {
      ...rules[rule],
      message: messages[rule],
    });
  });
};
