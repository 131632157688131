import axios from '@/libs/axios';

export default {
  run: false,
  controller: (new AbortController()),
  /**
   * Send request for get groups
   * @returns {Object}
   */
  async groups(query = {}) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();

    const res = await axios.get('groups', {
      signal: this.controller.signal,
      params: query,
    });

    this.run = false;

    return res;
  },

  /**
   * Send request for get groups top
   * @returns {Object}
   */
  async groupsTop(query) {
    const res = await axios.get('groups/top', { params: query });
    return res;
  },

  /**
   * Send request for get groups
   * @returns {Object}
   */
  async groupsMy(query) {
    if (this.run) this.controller.abort();
    this.run = true;
    this.controller = new AbortController();
    const res = await axios.get('groups/my', {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Send request for get groups
   * @returns {Object}
   */
  async groupsRequest(data) {
    const res = await axios.post('groups', data);
    return res;
  },

  /**
   * Send request for update user
   * @param Number id
   * @return {Object}
   */
  async undoGroupsRequest(id) {
    const res = await axios.put('groups', { id });
    return res;
  },

  /**
   * Send request for get group by id
   * @param {number} id
   * @returns {Object}
   */
  async group(id) {
    const res = await axios.get(`groups/${id}`);
    return res;
  },

  /**
   * Get Users of Group by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async getUsersGroup(id, query = null) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();
    const res = await axios.get(`groups/users/${id}`, {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Get Users of Group by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async getProjectsGroup(id, query = null) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();
    const res = await axios.get(`groups/projects/${id}`, {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Send request for get group by id
   * @param {number} id
   * @returns {Object}
   */
  async actionUser(id, data) {
    const res = await axios.post(`groups/users/${id}`, data);
    return res;
  },

  /**
   * Get Feeds of Group by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async getFeeds(id, query = null) {
    if (this.run) this.controller.abort();
    this.run = true;
    this.controller = new AbortController();
    const res = await axios.get(`groups/feed/${id}`, {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Get Feeds of Group by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async createFeed(id, data) {
    const res = await axios.post(`groups/feed/${id}`, data);
    return res;
  },
};
