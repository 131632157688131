import axios from 'axios';
import store from '@/store';
import router from '@/router';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const AuthorizationToken = localStorage.getItem('token');
    const token = btoa(`${process.env.VUE_APP_API_LOGIN}:${process.env.VUE_APP_API_PASSWORD}`);
    const originalRequest = config;
    if (AuthorizationToken) {
      originalRequest.headers['X-Api-Key'] = AuthorizationToken;
    }
    originalRequest.headers.Authorization = `Basic ${token}`;
    return originalRequest;
  },
  (err) => {
    Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    store.commit('SET_AUTH_USER', false);
    localStorage.removeItem('token');
    router.push({ name: 'login' });
  }
  return Promise.reject(error);
});

export default axiosInstance;
