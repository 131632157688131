import axios from '@/libs/axios';

export default {
  /**
   * Send request for get content
   * @returns {Object}
   */
  async get(path) {
    const res = await axios.get('cms/page', { params: { path } });
    return res;
  },

  /**
   * Send request for get press
   * @returns {Object}
   */
  async getPress(query = {}) {
    const res = await axios.get('cms/press', { params: query });
    return res;
  },

  /**
   * Send request for get press
   * @returns {Object}
   */
  async getPressSingle(id) {
    const res = await axios.get(`cms/press/${id}`);
    return res;
  },

  /**
   * Send request for get press
   * @returns {Object}
   */
  async getAbout() {
    const res = await axios.get('cms/about');
    return res;
  },

  /**
   * Send question from page contact
   * @returns {Object}
   */
  async sendQuestion(data) {
    const res = await axios.post('cms/contact', data);
    return res;
  },
};
