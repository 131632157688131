import multiguard from 'vue-router-multiguard';
import GuestGuard from '../guards/Guest';
import TokenUrlGuard from '../guards/TokenUrl';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/authorization/Login.vue'),
    beforeEnter: multiguard([GuestGuard]),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: true,
        },
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/authorization/Register.vue'),
    beforeEnter: multiguard([GuestGuard]),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: true,
        },
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/authorization/forgotPassword/Index.vue'),
    // beforeEnter: multiguard([GuestGuard]),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: true,
        },
      },
    },
  },
  {
    path: '/',
    name: 'mainAuthorization',
    component: () => import(/* webpackChunkName: "main" */ '@/views/authorization/Main.vue'),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: false,
        },
      },
    },
  },
  {
    path: '/profile/confirm',
    beforeEnter: multiguard([TokenUrlGuard]),
    component: () => import(/* webpackChunkName: "confirmForgotPassword" */ '@/views/authorization/confirm/ForgotPassword.vue'),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: true,
        },
      },
    },
  },
  {
    path: '/register/confirm',
    beforeEnter: multiguard([TokenUrlGuard]),
    component: () => import(/* webpackChunkName: "confirmRegister" */ '@/views/authorization/confirm/Register.vue'),
    meta: {
      layout: 'authorization-layout',
      settingLayout: {
        header: {
          transperent: true,
        },
      },
    },
  },
];
