<template>
  <div id="app">
    <Header :header="settingLayout.header" v-if="group.id"/>
    <GroupBanner
      v-if="group.id"
      :name="group.name"
      :description="group.description"
      :color="group.style_color"
      :membersLength="usersLength"
      :projectsLength="group.cnt_project"
    />
    <div class="user-cabinet">
      <ProjectHeader
        v-if="group.id"
        :users="users"
        :membersLength="usersLength"
        :alertText="text"
        :alertVariant="variant"
        :groupID="group.id"
        :role="group.role"
        :type="group.type"
        :isActivity="group.is_activity"
        v-on:invite="onInvite"
        v-on:search="onSearch"
      />
      <router-view
        :role="group.role"
        :search-members="searchMembers"
        :search-pagination="searchPagination"
        :search-text="searchText"
        @changeCountUsers="onChangeCountUsers"
        @changeUsers="onChangeUsers"
        @setGroup="onSetGroup"
      />
    </div>
    <Cookie v-if="!isAccepedCookie" @onAcceptCookie="acceptCookie"/>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import Cookie from '@/components/layout/Cookie.vue';
import GroupBanner from '@/views/profile/MyGroups/Group/Components/GroupBanner.vue';
import ProjectHeader from '@/views/profile/MyGroups/Group/Components/ProjectHeader.vue';

import alert from '@/mixins/alert';
import cookie from '@/mixins/cookie';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

import UserService from '@/services/UserService';

export default {
  name: 'GroupPage',
  mixins: [alert, cookie, websocket, me],
  components: {
    Header,
    Cookie,
    Footer,
    GroupBanner,
    ProjectHeader,
  },
  props: {
    settingLayout: {
      type: Object,
    },
  },
  data() {
    return {
      group: {
        admin: false,
        name: '',
        description: '',
        cnt_user: 0,
        cnt_project: 0,
        users: [],
        is_activity: false,
      },
      searchMembers: null,
      searchPagination: null,
      searchText: undefined,
      users: [],
      usersLength: 0,
    };
  },
  created() {
    this.$eventBus.$on('changeActivity', this.onChangeActivity);
    this.$eventBus.$on('showAlert', this.showAlert);
    this.$eventBus.$on('updateUserRole', this.updateUserRole);
  },
  methods: {
    async onInvite(data) {
      let res = null;
      try {
        res = await UserService.invite(data);
        this.setAlert({ textArg: res.data.message, variantArg: 'success' });
        setTimeout(() => {
          this.removeAlert();
        }, 5000);
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
    onSearch(members, pagination, searchText) {
      this.searchMembers = members;
      this.searchPagination = pagination;
      this.searchText = searchText;
    },
    onChangeCountUsers(count) {
      this.usersLength = count;
    },
    onChangeUsers(users) {
      this.users = users;
    },
    onSetGroup(group) {
      this.group = group;
      this.onChangeCountUsers(group.cnt_user);
      this.onChangeUsers(group.users);

      if (!this.$store.getters.getUnreadGroupProjects) {
        this.subscribeToChannel('group-projects', this.setUnreadGroupProjects, this.group.id);
        this.subscribeToChannel('group-members', this.setUnreadGroupMembers, this.group.id);
      }
    },
    onChangeActivity() {
      this.group.is_activity = !this.group.is_activity;
    },
    updateUserRole(role) {
      this.group.role = role;
    },
    showAlert(message, type = 'success') {
      this.showAndHideAlertSuccess(message, type);
    },
    setUnreadGroupProjects(event) {
      const data = JSON.parse(event.data).message;
      this.$store.commit('SET_UNREAD_GROUP_PROJECTS', data.projects);

      this.$eventBus.$emit('setProjectsCount', data
        .projects.reduce((acc, cur) => acc + cur.feed_cnt + cur.members?.length, 0), 'projects');
    },
    setUnreadGroupMembers(event) {
      const members = this.$store.getters.getUnreadGroupMembers || [];
      const data = JSON.parse(event.data).message;
      const newFriend = JSON.parse(event.data).update;

      this.$store.commit(
        'SET_UNREAD_GROUP_MEMBERS',
        [
          ...members,
          ...data.members.filter((mem) => !members.map((member) => member.id).includes(mem.id)),
        ],
      );

      // if (this.$route.name === 'profileGroupMembers') {
      //   this.$eventBus.$emit('setFriendsCount', 0, 'friends');
      // } else {
      this.$eventBus.$emit('setFriendsCount', data.members.length, 'friends');
      // }

      if (newFriend) {
        this.$eventBus.$emit('addedGroupMember', newFriend);
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('changeActivity', this.onChangeActivity);
    this.$eventBus.$off('showAlert', this.showAlert);
    this.$eventBus.$off('updateUserRole', this.updateUserRole);

    this.$store.commit('RESET_UNREAD_GROUP_PROJECTS');
    this.$store.commit('RESET_UNREAD_GROUP_MEMBERS');
  },
};
</script>
<style lang="scss" scoped>
  .container_alert {
    padding-top: 30px;
  }
</style>
