<template>
  <footer class="footer" id="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__item">
          <h5 class="footer__title">Follow us</h5>
          <ul class="social-list">
            <li class="social-list__item">
              <a target="_blank" href="https://www.facebook.com/weThink.Platform/"
                ><img src="@/assets/images/fb-icon.svg" alt="Facebook"
              /></a>
            </li>
            <li class="social-list__item">
              <a target="_blank" href="https://www.instagram.com/wethink.eu/"
                ><img src="@/assets/images/insta-icon.svg" alt="Instagram"
              /></a>
            </li>
            <li class="social-list__item">
              <a target="_blank" href="https://www.linkedin.com/company/thinkplatform/"
                ><img src="@/assets/images/in-icon.svg" alt="Linkedin"
              /></a>
            </li>
            <li class="social-list__item">
              <a target="_blank" href="https://twitter.com/weTHINK_europe"
                ><img src="@/assets/images/tw-icon.svg" alt="Twitter"
              /></a>
            </li>
          </ul>
          <ul class="social-list">
            <li>
          <a href='https://play.google.com/store/apps/details?id=eu.wethink.app&utm_source=wethink.eu&utm_campaign=play_store&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="160" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </li>
            <li>
          <router-link
            class="btn btn-secondary"
            style="margin-top: 5px;"
            :to="{ name: 'DonatePage' }"
            @click.native="onClick"
          >
            Donation
          </router-link>
          </li>
          </ul>
        </div>

        <div class="footer__item">
          <h5 class="footer__title">That‘s us</h5>
          <ul class="footer__list">
            <li><router-link :to="{ name: 'AboutPage' }">About</router-link></li>
            <li><router-link :to="{ name: 'ContactPage' }">Contact</router-link></li>
            <li><router-link :to="{ name: 'PressPage' }">Press</router-link></li>
          </ul>
        </div>

        <div class="footer__item">
          <h5 class="footer__title">Legal Stuff</h5>
          <ul class="footer__list">
            <li><router-link :to="{ name: 'PrivacyPage' }">Privacy</router-link></li>
            <li><router-link :to="{ name: 'LegalNoticePage' }">Legal Notice</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    onClick() {
      if (this.$route.name === 'DonatePage') {
        this.$router.go();
      } else {
        this.$router.push({ name: 'DonatePage' });
      }
    },
  },
};
</script>
