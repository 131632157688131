<template>
  <div class="user-banner">
    <div class="container">
      <div class="user-banner__inner">
        <div class="user-banner__avatar">
          <div v-if="!me.avatar"
            class="avatar"
            style="background-image: url('@/assets/images/avatar.svg')"
            alt="avatar"></div>
          <div v-else
            class="avatar"
            :style="'background-image: url(' + me.avatar + ')'"
            alt="avatar"></div>
        </div>
        <div class="user-banner__head">
          <div class="user-banner__name">{{ me.name }}
            <div class="user-banner__actions" v-if="isMatchmaking">
              <a :class="{active: isBuyer}" @click.prevent="setBuyer(true)">Buyer</a>
              <a :class="{active: !isBuyer}" @click.prevent="setBuyer(false)">Seller</a>
            </div>
          </div>
          <div class="user-banner__name-bg">{{ me.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setBuyer(predicate) {
      if (this.isLoading || this.$route.params.type === (predicate ? 'buyer' : 'seller')) return;

      if (predicate) {
        this.$store.commit('SET_IS_BUYER', true);
        this.$eventBus.$emit('setMatchmaking', this.$store.getters.getUnreadBuyerMarketplace, 'matchmaking');
        this.$router.push('/profile/matchmaking/buyer');
      } else {
        this.$store.commit('SET_IS_BUYER', false);
        this.$eventBus.$emit('setMatchmaking', this.$store.getters.getUnreadSellerMarketplace, 'matchmaking');
        this.$router.push('/profile/matchmaking/seller');
      }
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
    isBuyer() {
      return this.$route.params.type === 'buyer';
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    isMatchmaking() {
      return this.$route.path.includes('matchmaking');
    },
  },
};
</script>
<style lang="scss" scoped>
.user-banner__inner {
  align-items: center;
}

.user-banner__avatar {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin-right: 40px;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

input[type="file"] {
  display: none;
}
</style>
