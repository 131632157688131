export default {
  data() {
    return {
      menu: [
        {
          title: 'Matchmaking',
          slug: 'matchmaking',
          link: 'profileMatchmaking',
          icon: 'icon-strategy-project',
          active: false,
        },
        {
          title: 'Social feed',
          slug: 'feeds',
          link: 'profileFeed',
          icon: 'icon-feed',
          active: false,
        },
        {
          title: 'My Strategy Projects',
          slug: 'projects',
          link: 'strategyProjects',
          icon: 'icon-strategy-project',
          active: false,
        },
        {
          title: 'My Groups',
          slug: 'groups',
          link: 'profileGroups',
          icon: 'icon-my-groups',
          active: false,
        },
        {
          title: 'Friends',
          slug: 'friends',
          link: 'profileFriends',
          icon: 'icon-friends',
          active: false,
        },
        {
          title: 'About',
          slug: 'about',
          link: 'profileAbout',
          icon: 'icon-about',
          active: false,
        },
        {
          title: 'Settings',
          slug: 'settings',
          link: 'profileSetting',
          icon: 'icon-settings',
          active: false,
        },
      ],
    };
  },
};
