<template>
  <b-dropdown
    text="..."
    class="group_menu_btn"
    menu-class="dropdown-round-box group_menu_list">
    <b-dropdown-item-button class="group_menu_item" @click.prevent="onMute">
      <span
        :class="{ 'icon-mute': isActivity, 'icon-unmute': !isActivity}"
      >
      </span> {{muteText}}
    </b-dropdown-item-button>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item-button class="group_menu_item" @click.prevent="onLeave">
      <span class="icon-leave"></span> Leave Group
    </b-dropdown-item-button>
  </b-dropdown>
</template>
<script>
import {
  BDropdown,
  BDropdownItemButton,
  BDropdownDivider,
} from 'bootstrap-vue';

import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';

export default {
  name: 'ActivityGroupMenu',
  mixins: [exception],
  components: {
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
  },
  props: {
    isActivity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    muteText() {
      return this.isActivity ? 'Mute Group Activity' : 'Unmute Group Activity';
    },
  },
  methods: {
    async onMute() {
      let res = {};
      try {
        res = await GroupService.actionUser(this.$route.params.id, { action: this.isActivity ? 'mute' : 'unmute' });

        if (res.data.status === 200) {
          this.$eventBus.$emit('changeActivity');
        }
      } catch (e) {
        this.handleException(e);
      }
    },
    async onLeave() {
      let res = {};
      try {
        res = await GroupService.actionUser(this.$route.params.id, { action: 'leave' });

        if (res.data.status === 200) {
          this.$router.push({ name: 'profileGroups' });
        }
      } catch (e) {
        this.handleException(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .group_menu_btn {
    ::v-deep button.btn {
      width: 55px;
      height: 52px;

      background-color: #fff;
      box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
      border: 1px #EAEAEA solid;
      border-radius: 10px;

      color: #1D9DD9;
      font-size: 37px;

      margin: 0;
      padding: 0;
      padding-bottom: 17px;
    }

    ::v-deep .group_menu_list {
      padding: 22px 32px 22px 38px!important;
    }

    .group_menu_item {
      font-size: 14px;
      padding: 15px 0 15px 40px;
      border-bottom: 1px #B7B7B7 solid;
      position: relative;
      border: none;
    }

    .group_menu_item {
      ::v-deep button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>
