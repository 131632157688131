<template>
  <header class="header" :class="{ 'header-transparent': header.transperent }">
    <div class="container">
      <div class="header__inner">
        <div class="header__main">
          <router-link class="logo" to="/">
            <img src="@/assets/images/logo.svg" alt="WeThink"/>
          </router-link>
          <nav class="menu-main">
            <ul class="menu-main__list">
              <li>
                <router-link :to="{ name: 'PrivateGroupsPage' }">
                  Strategy Groups
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'strategyProjectsPage' }">
                  Strategy Projects
                </router-link>
              </li>
              <li>
                <a href="http://info.wethink.eu/" target="_blank">
                  News | Events | Partners
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header__action">
          <nav class="menu">
            <button class="menu__btn" :class="{ 'menu__btn--active': active }" @click="toggleMenu">
              <span class="menu__btn-line"></span>
            </button>
            <div class="menu-mobile" :class="{ 'menu-mobile--active': active }">
              <ul class="menu-mobile__list">
                <li v-if="authUser" @click="toggleMenu">
                  <router-link :to="{ name: 'MessagesPage' }" style="position: relative">
                    <span
                      v-if="unreadMessageCount"
                      class="message_badge"
                      :class="classBadgeMessagesLength">{{ unreadMessageCount }}
                    </span>
                    Messages
                  </router-link>
                </li>
                <li v-if="authUser" @click="toggleMenu" style="position: relative;">
                  <span
                    v-if="unreadProfileCount"
                    class="message_badge"
                    :class="classBadgeProfileLength">{{ unreadProfileCount }}
                  </span>
                  <router-link :to="{ name: 'profileSetting' }">
                    Profile
                  </router-link>
                </li>
                <li v-if="authUser" @click="logout">
                  <a>Logout</a>
                </li>
                <li v-if="!authUser" @click="toggleMenu">
                  <router-link :to="{ name: 'login' }"
                  >Login
                  </router-link
                  >
                </li>
              </ul>
              <ul class="menu-mobile-main__list">
                <li @click="toggleMenu">
                  <router-link
                    :to="{ name: 'PrivateGroupsPage' }"
                    @click="toggleMenu"
                  >Strategy Groups
                  </router-link>
                </li>
                <li @click="toggleMenu">
                  <router-link :to="{ name: 'strategyProjectsPage' }">
                    Strategy Projects
                  </router-link>
                </li>
                <li><a href="https://onemillionideas.eu">
                  Idea implementation
                </a></li>
                <li><a href="http://info.wethink.eu/">
                  ...
                </a></li>
              </ul>
            </div>
            <ul class="menu__list">
              <li v-if="authUser" style="position: relative;">
                <span
                  v-if="unreadMessageCount"
                  class="message_badge"
                  :class="classBadgeMessagesLength">{{ unreadMessageCount }}
                </span>
                <router-link :to="{ name: 'MessagesPage' }">
                  <i class="icon icon-message"></i>Messages
                </router-link>
              </li>
              <li v-if="authUser" style="position: relative;">
                <span
                  v-if="unreadProfileCount"
                  class="message_badge"
                  :class="classBadgeProfileLength">{{ unreadProfileCount }}
                </span>
                <router-link :to="{ name: 'profileSetting' }">
                  <i class="icon icon-profile"></i>Profile
                </router-link>
              </li>
              <li v-if="authUser" @click="logout">
                <a><i class="icon icon-home"></i>Logout</a>
              </li>
              <li v-if="!authUser">
                <router-link :to="{ name: 'register' }"
                ><i class="icon icon-home"></i>Register
                </router-link
                >
              </li>
              <li v-if="!authUser">
                <router-link
                  :to="{ name: 'login' }"
                  class="bordered"
                ><i class="icon icon-home"></i>Login
                </router-link
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import autorization from '@/mixins/autorization';
import me from '@/mixins/me';
import websocket from '@/mixins/websocket';
import badge from '@/mixins/badge';
import AutorizationService from '@/services/AutorizationService';

export default {
  mixins: [autorization, me, websocket, badge],
  props: {
    header: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
      socket: null,
      unreadMessageCount: 0,
      unreadProfileCount: 0,
      classBadgeMessagesLength: '',
      classBadgeProfileLength: '',
    };
  },
  created() {
    if (!this.authUser) return;

    this.subscribeToChannel('personal', this.setMessagesCountFromWebsocket);
  },
  computed: {
    authUser() {
      return this.$store.getters.getAuthUser;
    },
  },
  methods: {
    toggleMenu() {
      this.active = !this.active;
    },
    async logout() {
      try {
        await AutorizationService.logout();
        this.removeAutorization();
        this.toggleMenu();
      } catch (error) {
        console.log(error);
      }
    },
    setMessagesCountFromWebsocket(event) {
      const data = JSON.parse(event.data).message;

      this.unreadMessageCount = data.messages;
      this.unreadProfileCount = data.profile;
    },
  },
  watch: {
    unreadMessageCount() {
      this.classBadgeMessagesLength = this.getClassBadgeLength(this.unreadMessageCount);
    },
    unreadProfileCount() {
      this.classBadgeProfileLength = this.getClassBadgeLength(this.unreadProfileCount);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-main__list li {
  font-size: 12px;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    right: -20px;
  }
}
</style>
