<template>
  <div class="group-banner">
    <div class="container">
      <div class="group-banner__inner banner-gradient" :style="{ backgroundColor: color }">
        <div class="group-banner__head">
            <h2 class="group-banner__title">{{name}}</h2>
            <ul class="group-banner__statistic">
              <li><strong>{{membersLength}}</strong> Members</li>
              <li><strong>{{projectsLength}}</strong> Strategy Projects</li>
            </ul>
        </div>
        <div class="group-banner__info">{{description}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#1C5D99',
    },
    description: {
      type: String,
      default: '',
    },
    membersLength: {
      type: Number,
      default: 0,
    },
    projectsLength: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
    document.body.style.background = 'none';
  },
};
</script>
