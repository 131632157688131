export default {
  data() {
    return {
      text: null,
      variant: null,
      alert: {
        text: '',
        variant: '',
      },
    };
  },
  methods: {
    setAlert({ textArg, variantArg }) {
      this.text = textArg;
      this.variant = variantArg;
    },
    removeAlert() {
      this.text = null;
      this.variant = null;
    },
    showAndHideAlertSuccess(message, type = 'success') {
      this.setAlert({ textArg: message, variantArg: type });

      setTimeout(() => {
        this.removeAlert();
      }, 5000);
    },
    showAndHideAlert() {
      this.setAlert({ textArg: this.alert.text, variantArg: this.alert.variant });

      setTimeout(() => {
        this.removeAlert();
      }, 5000);
    },
  },
  watch: {
    'alert.text': function alertText() {
      if (!this.alert.text) {
        return;
      }

      this.showAndHideAlert();
    },
  },
};
