import axios from '@/libs/axios';

export default {
  run: false,
  controller: (new AbortController()),

  /**
   * Send request for get projects
   * @returns {Object}
   */
  async projects(query = {}) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();

    const res = await axios.get('projects', {
      signal: this.controller.signal,
      params: query,
    });

    this.run = false;

    return res;
  },

  /**
   * Send request for get projects tops
   * @returns {Object}
   */
  async projectsTop(query = {}) {
    const res = await axios.get('projects/top', { params: query });
    return res;
  },

  /**
   * Send request for get projects
   * @returns {Object}
   */
  async projectsMy(query = {}) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();
    const res = await axios.get('projects/my', {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Send request for get related projects
   * @returns {Object}
   */
  async getRelatedProjects(id, query = {}) {
    const res = await axios.get(`projects/related/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for get comments projects
   * @returns {Object}
   */
  async getComments(id, query = {}) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();
    const res = await axios.get(`projects/posts/${id}`, {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },

  /**
   * Send request for get projects
   * @returns {Object}
   */
  async getProjectsForAttach(query = {}) {
    const res = await axios.get('projects/attach', { params: query });
    return res;
  },

  /**
   * Send request for add comment to project
   * @returns {Object}
   */
  async addComment(id, data) {
    const res = await axios.post(`projects/posts/${id}`, data);
    return res;
  },

  /**
   * Send request for create pad of project
   * @returns {Object}
   */
  async create(data) {
    const res = await axios.post('projects', data);
    return res;
  },

  /**
   * Send request for create pad of project
   * @returns {Object}
   */
  async createPad(id, data) {
    const res = await axios.post(`projects/${id}`, data);
    return res;
  },

  /**
   * Send request for get projects
   * @returns {Object}
   */
  async addLike(id) {
    const res = await axios.post(`likes/projects/${id}`);
    return res;
  },

  /**
   * Send request for get projects
   * @returns {Object}
   */
  async removeLike(id) {
    const res = await axios.delete(`likes/projects/${id}`);
    return res;
  },

  /**
   * Send request for update text pad projects
   * @returns {Object}
   */
  async update(id, data) {
    const res = await axios.put(`projects/${id}`, data);
    return res;
  },

  /**
   * Send request for publish text pad projects
   * @returns {Object}
   */
  async requestPublish(id, data) {
    const res = await axios.post(`projects/publish/${id}`, data);
    return res;
  },

  /**
   * Send request for delete project
   * @returns {Object}
   */
  async delete(id) {
    const res = await axios.delete(`projects/${id}`);
    return res;
  },

  /**
   * Send request for undo projects request
   * @param Number id
   * @return {Object}
   */
  async undoProjectsRequest(id) {
    const res = await axios.put('projects', { id });
    return res;
  },

  /**
   * Send request for get project by id
   * @param {number} id
   * @returns {Object}
   */
  async project(id) {
    const res = await axios.get(`projects/${id}`);
    return res;
  },

  /**
   * Send request for get project by id
   * @param {number} id
   * @returns {Object}
   */
  async getData(id) {
    const res = await axios.get(`projects/text/${id}`);
    return res;
  },

  /**
   * Get Users of Project by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async getUsersProject(id, query = null) {
    const res = await axios.get(`projects/users/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for get project by id
   * @param {number} id
   * @returns {Object}
   */
  async actionUser(id, data) {
    const res = await axios.post(`projects/users/${id}`, data);
    return res;
  },

  /**
   * Get Feeds of Projects by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async getFeeds(id, query = null) {
    const res = await axios.get(`projects/feed/${id}`, { params: query });
    return res;
  },

  /**
   * Get Feeds of Project by
   * @param {number} id
   * @param {object} query
   * @returns {Object}
   */
  async createFeed(id, data) {
    const res = await axios.post(`projects/feed/${id}`, data);
    return res;
  },

  /**
   * Send request for get media of project
   * @returns {Object}
   */
  async getMedia(id, query = {}) {
    const res = await axios.get(`media/projects/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for store media of project
   * @returns {Object}
   */
  async addMedia(id, data) {
    const res = await axios.post(`media/projects/${id}`, data);
    return res;
  },
};
