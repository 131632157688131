<template>
  <div id="app">
    <router-view />
    <Cookie v-if="!isAccepedCookie" @onAcceptCookie="acceptCookie"/>
  </div>
</template>
<script>
import Cookie from '@/components/layout/Cookie.vue';

import content from '@/mixins/content';

import cookie from '@/mixins/cookie';

export default {
  props: {
    settingLayout: {
      type: Object,
    },
  },
  components: {
    Cookie,
  },
  mixins: [content, cookie],
  created() {},
  mounted() {
    document.body.style.minHeight = 'auto';
    document.body.style.background = 'none';
    document.body.style.backgroundSize = 'auto';
  },
  destroyed() {
    document.body.style.minHeight = '900px';
    document.body.style.background = 'url(/img/bg-stars.4a5a914e.svg) repeat 0 0';
    document.body.style.backgroundSize = '100%';
  },
};
</script>
