<template>
  <div class="modal" :id="id">
    <span
      v-if="showButtonCancelIcon"
      @click="$emit('close')"
      class="close">
      &times;
    </span>
    <div class="forgot__wrapper" :class="{disabled: !showIcon}">
      <h3 class="forgot__title">{{title}}</h3>
        <slot></slot>
        <div v-if="isFooter" class="forgot__footer">
          <button
            v-if="showButtonCancel"
            class="btn btn-primary btn-primary--invert"
            @click="$emit('cancel')"
            >
            {{buttonTextCancel}}
          </button>
          <button
            v-if="showButtonOk"
            class="btn btn-primary"
            @click="$emit('ok')"
            >
            {{buttonTextOk}}
          </button>
        </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    buttonTextOk: {
      type: String,
      default: 'Ok',
    },
    buttonVariantOk: {
      type: String,
      default: 'success',
    },
    buttonTextCancel: {
      type: String,
      default: 'Cancel',
    },
    buttonVariantCancel: {
      type: String,
      default: 'success',
    },
    showButtonCancelIcon: {
      type: Boolean,
      default: true,
    },
    showButtonCancel: {
      type: Boolean,
      default: false,
    },
    showButtonOk: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'modal',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
  },
  beforeDestroy() {
    document.querySelector('body').style.overflow = 'auto';
  },
};
</script>
<style lang="scss" scoped>
p{
  color: black;
}
.btns{
  display: flex;
  justify-content: space-around;
}
.modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  height: 400px;
}
.caption {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 700px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 0.3125rem;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  &_title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  p {
    margin: 10px 0px;
    font-size: 15px;
  }
}
.modal-content,
.caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

.forgot__wrapper {
  height: 100%;
  overflow: auto;
  &.disabled {
    &:before {
      background: none;
    }
  }
}
.modal-request-offer {
  overflow: hidden;
  padding-top: 40px;

  .forgot__wrapper {
    top: 0;
    height: auto;
    overflow: auto;
  }
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

</style>
