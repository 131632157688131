export default {
  methods: {
    setAutorization(headers) {
      localStorage.setItem('token', headers['x-api-key']);
      this.$store.commit('SET_AUTH_USER', true);
      this.$router.push({ name: 'profileMatchmaking' });
    },
    removeAutorization() {
      this.$store.commit('SET_AUTH_USER', false);
      this.$store.commit('REMOVE_ME');
      localStorage.removeItem('token');
      this.$router.push({ name: 'login' });
    },
  },
};
