<template>
  <div class="container">
    <b-dropdown
      v-if="role === 'guest'"
      id="dropdown-1"
      ref="dropdown"
      text="+"
      toggle-class="sproject-members__btn"
      menu-class="dropdown-round-box">
      <h3>Do you want to participate?</h3>
      <div class="sproject-members__btn-footer">
        <button
          type="button"
          class="btn btn-primary btn-primary--invert"
          @click="onCancelRequest"
        >
          No
        </button>
        <button type="button" class="btn btn-primary" @click="onRequestProject">Yes</button>
      </div>
    </b-dropdown>
    <ul class="sproject-members__list">
      <li
        v-for="user in users.slice(0,defaultNumberOfUser)"
        :key="user.name + user.email"
        @click="onShowUserPopap(user)
      ">
        <img v-if="!user.avatar" src="@/assets/images/avatar.svg" :title="user.name">
        <img v-else :src="user.avatar" :title="user.name">
      </li>
      <li v-if="countUsers > defaultNumberOfUser">
        <a @click="onShowAllUsers">+{{(countUsers - defaultNumberOfUser)}}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { BDropdown } from 'bootstrap-vue';

import ProjectService from '@/services/ProjectService';
import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';

export default {
  name: 'StrategyProjectViewHeader',
  components: { BDropdown },
  mixins: [exception],
  props: {
    users: {
      type: Array,
      required: true,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    role: {
      type: String,
      default: 'guest',
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultNumberOfUser: 5,
    };
  },
  created() {},
  methods: {
    async onRequestProject() {
      let res = null;
      const data = {
        action: 'request',
      };
      try {
        if (!this.isGroup) {
          res = await ProjectService.actionUser(this.$route.params.id, data);
        } else {
          res = await GroupService.actionUser(this.$route.params.id, data);
        }

        this.$refs.dropdown.hide();
        this.$eventBus.$emit('showAlert', res.data.message);
        this.$eventBus.$emit('updateUserRole', 'member');
      } catch (e) {
        if (e.response.status === 400) {
          this.$refs.dropdown.hide();
          console.log(e.response.data.message);
          this.$eventBus.$emit('showAlert', e.response.data.message, 'danger');
        }
      }
    },
    onCancelRequest() {
      this.$refs.dropdown.hide();
    },
    onShowUserPopap(user) {
      this.$eventBus.$emit('avatarClick', user);
    },
    onShowAllUsers() {
      if (this.$route.name === 'profileGroupsView'
        || this.$route.name === 'profileGroupStrategyProjects'
        || this.$route.name === 'profileGroupMembers') {
        this.$router.push({ name: 'profileGroupMembers' });
      }

      this.defaultNumberOfUser = this.users.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
}
.sproject-members__list {
  width: 100%;
}
::v-deep .sproject-members__btn {
  border: none;
  padding: 0;
  font-weight: 400;
}
::v-deep .dropdown-menu {
  width: 275px;
}
::v-deep .sproject-members__btn-footer a {
  margin: 0 1.25px;
}
</style>
