export default {
  data() {
    return {
      menu: [
        {
          title: 'Feed',
          slug: 'feeds',
          link: 'profileGroupsView',
          icon: 'icon-feed',
          active: false,
        },
        {
          title: 'Strategy Projects',
          slug: 'projects',
          link: 'profileGroupStrategyProjects',
          icon: 'icon-strategy-project',
          active: false,
        },
        {
          title: 'Members',
          slug: 'friends',
          link: 'profileGroupMembers',
          icon: 'icon-friends',
          active: false,
        },
      ],
    };
  },
};
