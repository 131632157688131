<template>
  <div id="app">
    <Header :header="settingLayout.header" />
    <div class="registration">
      <router-view />
    </div>
    <Cookie v-if="!isAccepedCookie" @onAcceptCookie="acceptCookie"/>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue';
import Cookie from '@/components/layout/Cookie.vue';
import Footer from '@/components/layout/Footer.vue';

import cookie from '@/mixins/cookie';

export default {
  components: {
    Header,
    Cookie,
    Footer,
  },
  props: {
    settingLayout: {
      type: Object,
    },
  },
  mixins: [cookie],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
