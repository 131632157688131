import { extend } from 'vee-validate';

export const setCustomRules = () => {
  extend('hardPassword', {
    computesRequired: true,
    validate: (value) => {
      if (value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*_#?&])[A-Za-z\d@$!%*_#?&]{8,}$/)) {
        return true;
      }
      return false;
    },
    message: 'The {_field_} required minimum eight characters, at least one letter, one number and one special character(@$!%*_#?&).',
  });
  extend('website', {
    computesRequired: false,
    validate: (value) => {
      if (!value) {
        return false;
      }
      // eslint-disable-next-line no-useless-escape
      if (value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        return true;
      }
      return false;
    },
    message: 'The {_field_} must be a url website',
  });
};
