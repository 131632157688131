import ContentService from '@/services/ContentService';

export default {
  data() {
    return {
      path: this.$route.meta.path || this.$route.path,
      content: {
        block: [],
        list: [],
      },
    };
  },
  async created() {
    const content = await this.getContent();

    if (content.block || content.list) {
      this.content = content;
    }

    this.setHeadMetaData();
  },
  methods: {
    async getContent() {
      let res = {};

      try {
        res = await ContentService.get(this.path);

        return res.data;
      } catch (e) {
        return {
          block: [],
          list: [],
        };
      }
    },
    setHeadMetaData() {
      document.head.querySelector('title').textContent = this.content.title;
      document.head.querySelector('meta[name="description"]').setAttribute('content', this.content.description);
      document.head.querySelector('meta[name="keywords"]').setAttribute('content', this.content.keywords);
    },
    setPath(path) {
      this.path = path;
    },
  },
};
