<template>
  <component :settingLayout="settingLayout" :is='layout'></component>
</template>

<script>
import Authorization from '@/layout/Authorization.vue';
import Profile from '@/layout/Profile.vue';
import Group from '@/layout/Group.vue';
import Page from '@/layout/Page.vue';
import Editor from '@/layout/Editor.vue';

export default {
  components: {
    'authorization-layout': Authorization,
    'profile-layout': Profile,
    'group-layout': Group,
    'page-layout': Page,
    'editor-layout': Editor,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    settingLayout() {
      return this.$route.meta.settingLayout;
    },
  },
};
</script>
