import axios from '@/libs/axios';

export default {

  /**
   * Send request for get current user
   * @return {Promise}
   */
  async current() {
    const res = await axios.get('users');
    return res;
  },
  // /**
  //  * Send request for get all users
  //  * @return {Promise}
  //  */
  // async all() {
  //   const res = await axios.get('users');
  //   return res;
  // },

  /**
   * Send request for get user by params
   * @param {Object} params
   * @returns {Object}
   */
  async getUserByParams(params) {
    const res = await axios.get('invite/users', { params });
    return res;
  },

  /**
   * Send request for get user by params
   * @param {Object} data
   * @returns {Object}
   */
  async invite(data) {
    const res = await axios.post('invite/send', data);
    return res;
  },

  /**
   * Send request for get user by id
   * @param {Number} userId
   * @returns {Object}
   */
  async find(userId) {
    const res = await axios.get(`users/${userId}`);
    return res;
  },

  /**
   * Send request for create user
   * @param {{email: string, first_name: string,
   * last_name:string, password:string , password_repeat:string}} data
   * @returns {Object}
   */
  async create(data) {
    const res = await axios.post('users', data);
    return res;
  },

  /**
   * Send request for update user
   * @param {{email: string, first_name: string,
   * last_name:string, password:string , password_repeat:string}} data
   * @return {Object}
   */
  async update(data) {
    const res = await axios.put('users', data);
    return res;
  },

  /**
   * Send request for block user
   * @returns {Object}
   */
  async block() {
    const res = await axios.post('users/block');
    return res;
  },

  /**
   * Send request for get feeds
   * @returns {Object}
   */
  async feeds(query = null) {
    if (this.run) this.controller.abort();

    this.run = true;

    this.controller = new AbortController();
    const res = await axios.get('users/feed', {
      signal: this.controller.signal,
      params: query,
    });
    this.run = false;
    return res;
  },
};
